import React from 'react'
import { Layout, Meta } from '../components/layout'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import ReviewHeader from '../components/pages/reviews/reviewHeader'
import Testimonials from '../components/pages/reviews/testimonialsContainer'
import Trust from '../components/pages/reviews/trust.js'
import UserReviews from '../components/pages/reviews/userReviews'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Testimonial1 from '../images/Review/testimonial1.png'
import Testimonial2 from '../images/Review/testimonial2.png'
import Testimonial3 from '../images/Review/testimonial3.png'

const ReviewHeaderData = {
    title: `De best beoordeelde software van Nederland`,
    text1: `Ontdek waarom meer dan`,
    text2: `zzp'ers, freelancers, ondernemers en`,
    text3: `zelfstandigen Tellow vertrouwen.`,
    textCTA: `Probeer Tellow gratis`,
}

const TestimonialsData = {
    heading: `Wat onze klanten vertellen`,
    textCTA: `Bekijk alle onafhankelijke reviews`,
    url: 'https://www.klantenvertellen.nl/reviews/1039811/tellow',
    testimonials: [
        {
            title: `Altijd actief advies dankzij Compleet`,
            text: `Zelf ben ik freelancer in de IT en door de applicatie was het erg makkelijk om mijn administratie bij te houden. Tellow heeft me ook een eigen boekhouder gegeven, zodat ik maximaal bespaar, extra controle heb en altijd vragen kan stellen over de telefoon.`,
            name: `Sophie`,
            position: `Business analyst`,
            img: Testimonial1,
        },

        {
            title: `Super simpel te gebruiken, met een ijzersterke klantenservice`,
            text: `Voor het bijhouden van drie boekhoudingen gebruik ik Tellow. Het werkt erg simpel en snel. Het pakket doet wat het moet doen en zorgt ervoor dat ik meer tijd overhoud voor andere zaken. Als ik ergens tegenaan loop is de support altijd erg goed en snel.`,
            name: `Hans`,
            position: `Bouwvakker`,
            img: Testimonial2,
        },
        {
            title: `Super handige begeleiding en vlotte hulp`,
            text: `Superhandige app, vooral als je zelf niet helemaal bekend bent met het boekhouden. Ik raakte even de weg kwijt en wist niet meer precies hoe credit/debit stortingen en opnames ingevuld moesten worden, via de chat was het in een paar minuten geregeld.`,
            name: `Muhammad`,
            position: `Zonnepanelen installateur`,
            img: Testimonial3,
        },
    ],
}

const TrustData = {
    trustComponents: [
        {
            number: `9.0`,
            text: `Onze score van honderden </br> onafhankelijke <a href="https://www.klantenvertellen.nl/reviews/1039811/tellow" target="_blank"><u>reviews</u></a>`,
        },
        {
            number: `100,000+`,
            text: `Ondernemers vertrouwen </br> ons met hun financiën`,
        },
        {
            number: `92%`,
            text: `van onze gebruikers beveelt </br> onze software aan`,
        },
    ],
}

const UserReviewsData = {
    title: `Nog meer ondernemers die </br> Tellow ontzorgd`,
    testimonialCard: [
        {
            review: `Het systeem kan mijn inkoopfacturen in PDF-formaat automatisch lezen en de gegevens voor mij invullen. Zo zorgt Tellow ervoor dat mijn boekhouding klopt op basis van facturen en bankgegevens.`,
            name: `Maria`,
            position: `Yoga instructeur`,
            stars: 5,
        },
        {
            review: `Zelf eerst het starterspakket genomen. Het beviel zo goed dat ik het beste pakket koos en toen werd het pas echt super! De service is top, zowel bellen als mailen, en je wordt direct geholpen met het juiste advies!`,
            name: `Jopie`,
            position: `Glazenwasser`,
            stars: 5,
        },
        {
            review: `Tot nu toe zeer tevreden met Tellow. Het programma zelf is erg intuïtief en ik kwam er zelf snel uit. Aanrader voor elke startende ondernemer!`,
            name: `Tom`,
            stars: 5,
            position: `IT professional`,
        },
        {
            review: `Ik had door mijn bank een probleem met mijn bankkoppeling wat moeilijk op te lossen was. Maron van het Tellow Support team heeft er bovenop gezeten tot het probleem verholpen was.`,
            name: `Hanna`,
            stars: 5,
            position: `Zorgmedewerker`,
        },
        {
            review: `Wat een top service! Om 21:00 uur nog een chatbericht gestuurd en kreeg direct antwoord (wat ik niet had verwacht); een paar minuutjes later was mijn probleem volledig opgelost.`,
            name: `Jose`,
            stars: 5,
            position: `Teamleider sales`,
        },
        {
            review: `Vragen aan Tellow medewerkers worden zowel per mail als via de app snel beantwoord en als het dan nog niet duidelijk is is telefonische assistentie beschikbaar. En dat alles tegen een heel redelijk tarief.`,
            name: `Jan`,
            stars: 4.5,
            position: `Commercieel adviseur`,
        },
        {
            review: `Fijn dat Remco de missende bankposten snel kon inlezen. Hij heeft aantal missende buitenlandse overschrijvingen apart voor me ingelezen, zodat ik mijn facturen dezelfde dag kon boeken.`,
            name: `Marco`,
            stars: 4.5,
            position: `Data analyst`,
        },
        {
            review: `Tellow is perfect voor de kleine ondernemer. Een klantvriendelijk pakket met veel mogelijkheden. Prima support bij vragen. Reageren echt snel en adequaat. Fijn om mee te werken.`,
            name: `André `,
            stars: 5,
            position: `Productontwikkelaar`,
        },
        {
            review: `Ik kan niet omschrijven hoe veel moeite Tellow mij bespaart met mijn boekhouden. Een user-friendly design, speciale features voor Nederlandse bedrijven en top klantenservice. Zeker een aanrader.`,
            name: `Adam`,
            stars: 5,
            position: `Business developer`,
        },
    ],
}

const Review = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'boekhouden', 'reviews', 'tellow']}
            title="Reviews - 92% van onze klanten beveelt ons aan | Tellow.nl"
            description="100.000+ ondernemers gingen je al voor en zij beoordelen ons met gemiddeld een 8,8! Lees hier wat zij van Tellow vinden."
            path="/en/reviews"
            jsonLD={reviewStructuredData}
        />
        <ReviewHeader data={ReviewHeaderData} />

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <Trust data={TrustData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Testimonials data={TestimonialsData} />
            </ComponentContainer>
        </RelativeContainer>

        <UserReviews data={UserReviewsData} />
    </Layout>
)

export default Review
